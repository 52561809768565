import { format } from 'date-fns'
import LoadingTable from 'src/common/LoadingTable'
import SappTooltip from 'src/common/SappTooltip'
import SappTable from 'src/components/base/SappTable'
import SAPPCheckbox from 'src/components/base/checkbox/SAPPCheckbox'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import { IPromotionCode, IPromotionCodes } from 'src/type/sales/promotionCodes'
import { cleanParamsAPI } from 'src/utils'

interface IProps {
  data?: IPromotionCodes
  isLoading: boolean
  fetchPromotionCodes: (pageIndex: number, pageSize: number, params?: Object) => void
  getParams: () => Object
  handleChangeParams: (pageIndex: number, pageSize: number, params?: Object) => void
  checkedList: any
  toggleCheck: any
  toggleCheckAll: any
  isCheckedAll: boolean
}

// define headers
const headers = [
  {
    label: 'Code',
    className: 'min-w-100px',
  },
  {
    label: 'Total',
    className: 'min-w-100px',
  },
  {
    label: 'Used',
    className: 'min-w-100px pe-5',
  },
  {
    label: 'Start',
    className: 'min-w-100px pe-5',
  },
  {
    label: 'End',
    className: 'min-w-100px pe-5',
  },
  {
    label: 'Apply for',
    className: 'min-w-100px pe-5',
  },
  {
    label: 'Status',
    className: 'min-w-100px pe-5',
  },
]

const PromotionCodeTable = ({
  data,
  isLoading,
  fetchPromotionCodes,
  getParams,
  handleChangeParams,
  checkedList,
  toggleCheck,
  toggleCheckAll,
  isCheckedAll,
}: IProps) => {
  const handlePaginationChange = (pageIndex: number, pageSize: number) => {
    const cleanedParams = cleanParamsAPI(getParams())

    handleChangeParams(pageIndex, pageSize, cleanedParams)
    fetchPromotionCodes(pageIndex, pageSize, cleanedParams)
  }

  return (
    <div className='card-body py-4'>
      <SappTable
        headers={headers}
        loading={isLoading}
        data={data?.promotionCodes}
        isCheckedAll={isCheckedAll}
        hasCheckAll={true}
        classNameSelect={'d-flex justify-content-center'}
        onChange={() => {
          toggleCheckAll(!isCheckedAll, true)
        }}
      >
        {isLoading ? (
          <LoadingTable headers={headers} />
        ) : (
          <>
            {data?.promotionCodes?.map((promotionCode: IPromotionCode, index: number) => {
              const isChecked = checkedList.includes(promotionCode?.id)

              let productNames = ''
              if (promotionCode.products?.length > 1) {
                productNames = `Chuỗi khóa promotionCode "${promotionCode.products
                  .map((product) => product.name)
                  .join('; ')}"`
              } else {
                productNames = `${promotionCode.products.map((product) => product.name)}`
              }

              return (
                <tr key={promotionCode?.id}>
                  <td className='text-center w-50px px-0'>
                    <SAPPCheckbox
                      checkTarget='.form-check-input'
                      checked={isChecked}
                      ktCheck={isChecked}
                      onChange={() => {
                        toggleCheck(promotionCode?.id!)
                      }}
                      className='justify-content-center'
                    />
                  </td>
                  <td className='text-start'>{promotionCode?.code}</td>
                  <td className='text-start'>{promotionCode?.quantity}</td>
                  <td className='text-start'>{promotionCode?.used_promotion_count}</td>
                  <td className='text-start'>
                    {format(new Date(promotionCode.start_date), 'dd/MM/yyyy')}
                  </td>
                  <td className='text-start'>
                    {format(new Date(promotionCode.end_date), 'dd/MM/yyyy')}
                  </td>
                  <td className='text-start'>
                    <SappTooltip title={productNames} />
                  </td>
                  <td className='text-start'>{promotionCode.status}</td>
                </tr>
              )
            })}
          </>
        )}
      </SappTable>
      <PagiantionSAPP
        currentPage={data?.metadata?.page_index || 1}
        pageSize={data?.metadata?.page_size || 10}
        totalItems={data?.metadata?.total_records || 0}
        handlePaginationChange={handlePaginationChange}
      />
    </div>
  )
}

export default PromotionCodeTable
