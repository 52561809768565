/* eslint-disable @typescript-eslint/no-unused-vars */
import { zodResolver } from '@hookform/resolvers/zod'
import { Select } from 'antd'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useLocation, useNavigate } from 'react-router-dom'
import { KTIcon } from 'src/_metronic/helpers'
import { PromotionCodeAPI } from 'src/apis/sales/promotion-codes'
import PopupConfirm from 'src/common/PopupConfirm'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import withAuthComponents from 'src/components/auth/with-auth-components'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import RangeDateTimePicker from 'src/components/base/rangeDateTime/RangeDateTimePicker'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import ListFilterLayout from 'src/components/layout/listFilter'
import PageLayouts from 'src/components/layout/PageLayouts'
import PromotionCodeTable from 'src/components/sales/promotion-codes/PromotionCodeTable'
import { FILTER_SELECTALL_SORTBY, PageLink } from 'src/constants'
import { DELETE_POPUPCONFIRM, LANG_SIDEBAR } from 'src/constants/lang'
import { OPTIONS_DISCOUNT_TYPE, OPTIONS_PROMOTION_CODE_STATUS } from 'src/constants/sales'
import { ITabs } from 'src/type'
import { IPromotionCodeFilter, IPromotionCodes } from 'src/type/sales/promotionCodes'
import { cleanParamsAPI, formatISOFromDate, getDateInfo } from 'src/utils'
import { z } from 'zod'

const { Option } = Select

const breadcrumbs: ITabs[] = [
  {
    link: `#`,
    title: `${LANG_SIDEBAR.operations}`,
  },
  {
    link: `#`,
    title: `${LANG_SIDEBAR.sales}`,
  },
  {
    link: `${PageLink.OPERATIONS_SALES_PROMOTION_CODES}`,
    title: `${LANG_SIDEBAR.promotionCodes}`,
  },
]

const PromotionCodes = () => {
  const [data, setData] = useState<IPromotionCodes>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false)
  const [deleteId, setDeleteId] = useState<string | null>(null)
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search])

  useEffect(() => {
    navigate('')
    fetchPromotionCodes(1, 10)
  }, [])

  /**
   * Validate cho các trường filter
   */
  const validationSchema = z.object({
    title: z.string().optional(),
    category: z.any().optional(),
    status: z.any().optional(),
    sortType: z.string().optional(),
    rangeDate: z.any().optional(),
  })

  const { control, handleSubmit, getValues, reset } = useForm<IPromotionCodeFilter>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
  })

  const fetchPromotionCodes = useCallback(
    async (pageIndex: number, pageSize: number, params?: Object) => {
      setIsLoading(true)
      try {
        const res = await PromotionCodeAPI.getPromotionCodes({
          page_index: pageIndex,
          page_size: pageSize,
          params,
        })
        setData(res?.data)
      } catch (error) {
        // Handed by axios interceptors
      } finally {
        setIsLoading(false)
      }
    },
    [setIsLoading, setData]
  )

  const getValuesFilter = () => {
    const inputRangeDate = getValues('rangeDate')
    const dateInfoFromDate = inputRangeDate?.fromDate ? getDateInfo(inputRangeDate.fromDate) : null
    const dateInfoToDate = inputRangeDate?.toDate ? getDateInfo(inputRangeDate.toDate) : null

    return {
      code: getValues('code'),
      category: getValues('category'),
      status: getValues('status'),
      sortType: getValues('sortType'),
      startDate: dateInfoFromDate
        ? formatISOFromDate(dateInfoFromDate.year, dateInfoFromDate.month, dateInfoFromDate.day)
        : '',
      endDate: dateInfoToDate
        ? formatISOFromDate(dateInfoToDate.year, dateInfoToDate.month, dateInfoToDate.day)
        : '',
    }
  }

  const getParams = () => ({
    code: searchParams.get('code'),
    category: searchParams.get('category'),
    status: searchParams.get('status'),
    sortType: searchParams.get('sortType'),
    startDate: searchParams.get('startDate'),
    endDate: searchParams.get('endDate'),
  })

  const handleChangeParams = (pageIndex: number, pageSize: number, params?: Object) => {
    const queryParams = {
      page_index: pageIndex,
      page_size: pageSize,
      ...params,
    }

    const queryString = Object.entries(queryParams)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    navigate(`?${encodeURI(queryString)}`)
  }

  const handleFilter = () => {
    const cleanedParams = cleanParamsAPI(getValuesFilter())

    handleChangeParams(1, 10, cleanedParams)
    fetchPromotionCodes(1, 10, cleanedParams)
  }

  const handleResetFilter = () => {
    reset()
    navigate('')
    fetchPromotionCodes(1, 10)
  }

  const handleDelete = async () => {
    try {
      if (deleteId) {
        const res = await PromotionCodeAPI.deletePromotionCode(deleteId)
        if (res?.success) {
          toast.success('Delete promotion code successfully')
          setDeleteId(null)
          navigate('')
          fetchPromotionCodes(1, 10)
        }
      }
    } catch (error) {
      // Handed by axios interceptors
    } finally {
      setIsOpenDeleteModal(false)
    }
  }

  return (
    <PageLayouts pageTitle={LANG_SIDEBAR.promotionCodes} breadcrumbs={breadcrumbs}>
      <div className='card'>
        {/* start:: filter */}
        <div className='px-10 border-0 pt-10'>
          <ListFilterLayout>
            <HookFormTextField
              control={control}
              name='code'
              placeholder='Code name'
              defaultValue=''
              isListScreen
              onSubmit={handleFilter}
            />

            <HookFormSelectAntd
              control={control}
              name='category'
              placeholder='Category'
              size='large'
              classNameHeight='sapp-h-40'
            >
              {OPTIONS_DISCOUNT_TYPE.map((status) => (
                <Option key={status.label} value={status.value}>
                  {status.label}
                </Option>
              ))}
            </HookFormSelectAntd>

            <HookFormSelectAntd
              control={control}
              name='status'
              placeholder='Status'
              size='large'
              classNameHeight='sapp-h-40'
            >
              {OPTIONS_PROMOTION_CODE_STATUS.map((status) => (
                <Option key={status.label} value={status.value}>
                  {status.label}
                </Option>
              ))}
            </HookFormSelectAntd>

            <HookFormSelectAntd
              control={control}
              name='sortType'
              placeholder='Sort by'
              size='large'
              classNameHeight='sapp-h-40'
            >
              {FILTER_SELECTALL_SORTBY.map((status) => (
                <Option key={status.label} value={status.value}>
                  {status.label}
                </Option>
              ))}
            </HookFormSelectAntd>

            <RangeDateTimePicker
              control={control}
              name='rangeDate'
              allowClear={true}
              placeholder={['From date', 'To date']}
            />
          </ListFilterLayout>
        </div>
        <div className='card-header border-0 pt-5 px-10'>
          <div className='d-flex'>
            <SAPPFIlterButton
              titleReset='Reset'
              titleSubmit='Search'
              okClick={handleSubmit(handleFilter)}
              resetClick={handleResetFilter}
              disabled={isLoading}
              loading={isLoading}
            />
          </div>

          <div className='col-sm-8 col-xl-8 col-lg-4 px-xl-3 px-md-0 px-0 pe-xl-0'>
            <div className='card-toolbar justify-content-end d-flex my-0 gap-2'>
              <ButtonIcon
                title='New Promotion Code'
                isListScreen
                onClick={() => navigate(`${PageLink.OPERATIONS_SALES_PROMOTION_CODES}/create`)}
              >
                <KTIcon iconName='plus' className='fs-2' />
              </ButtonIcon>
            </div>
          </div>
        </div>
        {/* end:: filter */}

        {/* start:: table */}
        <PromotionCodeTable
          data={data}
          isLoading={isLoading}
          fetchPromotionCodes={fetchPromotionCodes}
          handleChangeParams={handleChangeParams}
          getParams={getParams}
          setDeleteId={setDeleteId}
          setIsOpenDeleteModal={setIsOpenDeleteModal}
        />
        {/* end:: table */}

        <PopupConfirm
          open={isOpenDeleteModal}
          setOpen={setIsOpenDeleteModal}
          onClick={handleDelete}
          onClose={() => setIsOpenDeleteModal(false)}
          okButtonCaption='Yes'
          cancelButtonCaption='No'
          body={DELETE_POPUPCONFIRM}
        />
      </div>
    </PageLayouts>
  )
}

export default withAuthComponents(PromotionCodes)
