import { Select } from 'antd'
import { Dispatch, SetStateAction, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { SaleProductAPI } from 'src/apis/sales/products'
import LoadingTable from 'src/common/LoadingTable'
import SappTooltip from 'src/common/SappTooltip'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import SappTable from 'src/components/base/SappTable'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import { OPTIONS_PRODUCT_STATUS } from 'src/constants/sales'
import { useConfirm } from 'src/hooks/use-confirm'
import { ISaleProduct, ISaleProducts } from 'src/type/sales/products'
import { cleanParamsAPI } from 'src/utils'
import SaleProductAction from './SaleProductAction'

const { Option } = Select

interface IProps {
  saleProducts?: ISaleProducts
  loading: boolean
  fetchData: (pageIndex: number, pageSize: number, params?: Object) => void
  handleChangeParams: (pageIndex: number, pageSize: number, params?: Object) => void
  getParams: () => Object
  setDataUpdate: Dispatch<SetStateAction<ISaleProduct | null>>
  setIsOpenUpdateModal: Dispatch<SetStateAction<boolean>>
  setDeleteId: Dispatch<SetStateAction<string | null>>
  setIsOpenDeleteModal: Dispatch<SetStateAction<boolean>>
}

const headers = [
  {
    label: '#',
    className: 'min-w-30px px-2 text-center',
  },
  {
    label: 'Product name',
    className: 'min-w-250px pe-5 ',
  },
  {
    label: 'Category',
    className: 'min-w-150px pe-5',
  },
  {
    label: 'Construction mode',
    className: 'min-w-150px pe-5',
  },
  {
    label: "Product's code",
    className: 'min-w-150px pe-5',
  },
  {
    label: 'Status',
    className: 'max-w-110px pe-5',
  },
  {
    label: '',
    className: 'min-w-50px pe-5',
  },
]

const SaleProductTable = ({
  saleProducts,
  loading,
  fetchData,
  handleChangeParams,
  getParams,
  setDataUpdate,
  setIsOpenUpdateModal,
  setDeleteId,
  setIsOpenDeleteModal,
}: IProps) => {
  const { confirm, contextHolder } = useConfirm()

  // Using validate for input
  const { control, setValue } = useForm<any>({
    mode: 'onChange',
  })

  // Set default value input status
  useEffect(() => {
    saleProducts?.products.forEach((item, index) => {
      setValue(`status${index}`, item.status)
    })
  }, [saleProducts])

  const handleChangeStatus = (id: string, name: string, value: string, oldValue: string) => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn đổi trạng thái không?'],
      onClick: async () => {
        try {
          const res = await SaleProductAPI.updateSaleProductStatus(id, {
            status: value,
          })
          if (res?.success) {
            toast.success('Update status successfully!')
          }
        } catch (error) {
          // Handed by axios interceptors
        }
      },
      onClose: () => setValue(name, oldValue),
    })
  }

  const handlePaginationChange = (pageIndex: number, pageSize: number) => {
    const cleanedParams = cleanParamsAPI(getParams())

    handleChangeParams(pageIndex, pageSize, cleanedParams)
    fetchData(pageIndex, pageSize, cleanedParams)
  }

  return (
    <div className='card-body pt-7'>
      {contextHolder}
      <SappTable
        headers={headers}
        loading={loading}
        data={saleProducts?.products}
        hasCheck={false}
        isCheckedAll
      >
        {loading ? (
          <LoadingTable headers={headers} />
        ) : (
          <>
            {saleProducts?.products?.map((item: ISaleProduct, index: number) => (
              <tr key={index}>
                <td className='px-2 text-center'>
                  {index +
                    1 +
                    (saleProducts?.metadata?.page_index - 1) * saleProducts?.metadata?.page_size}
                </td>
                <td className='text-start'>
                  <div
                    onClick={() => {
                      setDataUpdate(item)
                      setIsOpenUpdateModal(true)
                    }}
                  >
                    <SappTooltip title={item.name} link='#' />
                  </div>
                </td>
                <td className='text-start'>{item.course_category?.name}</td>
                <td className='text-start'>{item.construction_mode}</td>
                <td className='text-start'>{item.code}</td>
                <td className='text-start'>
                  <HookFormSelectAntd
                    size='large'
                    name={`status${index}`}
                    control={control}
                    dropdownStyle={{ maxWidth: 100 }}
                    placeholder='Status'
                    filterOption={true}
                    onChange={(selectedValue: unknown) =>
                      handleChangeStatus(
                        item.id,
                        `status${index}`,
                        selectedValue as string,
                        item.status
                      )
                    }
                    classNameHeight='sapp-h-35px'
                    allowClear={false}
                  >
                    {OPTIONS_PRODUCT_STATUS.map((status) => (
                      <Option key={status.label} value={status.value}>
                        {status.label}
                      </Option>
                    ))}
                  </HookFormSelectAntd>
                </td>
                <td className='text-center'>
                  <SaleProductAction
                    key={index}
                    handleEdit={() => {
                      setDataUpdate(item)
                      setIsOpenUpdateModal(true)
                    }}
                    handleDelete={() => {
                      setDeleteId(item.id || null)
                      setIsOpenDeleteModal(true)
                    }}
                  />
                </td>
              </tr>
            ))}
          </>
        )}
      </SappTable>
      <PagiantionSAPP
        currentPage={saleProducts?.metadata?.page_index || 1}
        pageSize={saleProducts?.metadata?.page_size || 10}
        totalItems={saleProducts?.metadata?.total_records || 0}
        handlePaginationChange={handlePaginationChange}
      />
    </div>
  )
}
export default SaleProductTable
