/* eslint-disable @typescript-eslint/no-unused-vars */
import { zodResolver } from '@hookform/resolvers/zod'
import { Select } from 'antd'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useLocation, useNavigate } from 'react-router-dom'
import { KTIcon } from 'src/_metronic/helpers'
import { CoursesAPI } from 'src/apis/courses'
import { SaleProductAPI } from 'src/apis/sales/products'
import PopupConfirm from 'src/common/PopupConfirm'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import withAuthComponents from 'src/components/auth/with-auth-components'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'

import ListFilterLayout from 'src/components/layout/listFilter'
import PageLayouts from 'src/components/layout/PageLayouts'
import SaleProductSideModal from 'src/components/sales/products/SaleProductSideModal'
import SaleProductTable from 'src/components/sales/products/SaleProductTable'
import { FILTER_SELECTALL_SORTBY, PageLink } from 'src/constants'
import { DELETE_POPUPCONFIRM, LANG_SIDEBAR } from 'src/constants/lang'
import { OPTIONS_PRODUCT_STATUS } from 'src/constants/sales'
import useShopFilter from 'src/hooks/use-shop-filter'
import { ITabs } from 'src/type'
import { ISaleProduct, ISaleProductFilter, ISaleProducts } from 'src/type/sales/products'
import { cleanParamsAPI } from 'src/utils'
import { replaceValueAll } from 'src/utils/string'
import { z } from 'zod'
import './index.scss'

const { Option } = Select

const breadcrumbs: ITabs[] = [
  {
    link: `#`,
    title: `${LANG_SIDEBAR.operations}`,
  },
  {
    link: `#`,
    title: `${LANG_SIDEBAR.sales}`,
  },
  {
    link: `${PageLink.OPERATIONS_SALES_PRODUCTS}`,
    title: `${LANG_SIDEBAR.products}`,
  },
]

const SaleProducts = () => {
  const [saleProducts, setSaleProducts] = useState<ISaleProducts>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isOpenCreateModal, setIsOpenCreateModal] = useState<boolean>(false)
  const [isOpenUpdateModal, setIsOpenUpdateModal] = useState<boolean>(false)
  const [dataUpdate, setDataUpdate] = useState<ISaleProduct | null>(null)
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false)
  const [deleteId, setDeleteId] = useState<string | null>(null)
  const navigate = useNavigate()
  const location = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search])

  const {
    dataList: categoryList,
    getData: getCategory,
    debounceGetData: debounceGetCategory,
    handleNextPage,
  } = useShopFilter({
    callback: CoursesAPI.getCategory,
    type: 'category',
    key: 'course_categories',
  })

  useEffect(() => {
    navigate('')
    fetchSaleProducts(1, 10)
  }, [])

  /**
   * Validate cho các trường filter
   */
  const validationSchema = z.object({
    name: z.string().optional(),
    category_id: z.any().optional(),
    status: z.any().optional(),
    sortType: z.string().optional(),
  })

  const { control, handleSubmit, getValues, reset } = useForm<ISaleProductFilter>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
  })

  const fetchSaleProducts = useCallback(
    async (pageIndex: number, pageSize: number, params?: Object) => {
      setIsLoading(true)
      try {
        const res = await SaleProductAPI.getSaleProducts({
          params,
          page_index: pageIndex,
          page_size: pageSize,
        })
        setSaleProducts(res?.data)
      } catch (error) {
        // Handed by axios interceptors
      } finally {
        setIsLoading(false)
      }
    },
    [setIsLoading, setSaleProducts]
  )

  const getValuesFilter = () => ({
    name: getValues('name'),
    category_id: getValues('category_id'),
    status: getValues('status'),
    sortType: replaceValueAll(getValues('sortType')),
  })

  const getParams = () => ({
    name: searchParams.get('name'),
    category_id: searchParams.get('category_id'),
    status: searchParams.get('status'),
    sortType: searchParams.get('sortType'),
  })

  const handleChangeParams = (pageIndex: number, pageSize: number, params?: Object) => {
    const queryParams = {
      ...params,
      page_index: pageIndex,
      page_size: pageSize,
    }

    const queryString = Object.entries(queryParams)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    navigate(`?${encodeURI(queryString)}`)
  }

  const handleFilter = () => {
    const cleanedParams = cleanParamsAPI(getValuesFilter())

    handleChangeParams(1, 10, cleanedParams)
    fetchSaleProducts(1, 10, cleanedParams)
  }

  const handleResetFilter = () => {
    reset()
    navigate('')
    fetchSaleProducts(1, 10)
    getCategory()
  }

  const handleDelete = async () => {
    try {
      if (deleteId) {
        const res = await SaleProductAPI.deleteSaleProduct(deleteId)
        if (res?.success) {
          toast.success('Delete product successfully')
          navigate('')
          fetchSaleProducts(1, 10)
        }
      }
    } catch (error) {
      // Handed by axios interceptors
    } finally {
      setIsOpenDeleteModal(false)
    }
  }

  return (
    <PageLayouts pageTitle={LANG_SIDEBAR.products} breadcrumbs={breadcrumbs}>
      <div className='card'>
        <div className='px-10 border-0 pt-10'>
          <ListFilterLayout>
            {/* Product name */}
            <HookFormTextField
              control={control}
              name='name'
              placeholder="Product's name or code"
              defaultValue={''}
              isListScreen
              onSubmit={handleFilter}
            />

            {/* Category */}
            <HookFormSelectAntd
              name='category_id'
              placeholder='Category'
              control={control}
              size='large'
              showSearch
              handleNextPage={handleNextPage}
              classNameHeight='sapp-h-40'
              onSearch={async (e) => {
                await debounceGetCategory(e)
              }}
              onFocus={async () => {
                if (categoryList.data?.length === 0) {
                  await getCategory()
                }
              }}
              onChange={async (e: any) => {
                if (e === undefined) {
                  await debounceGetCategory()
                }
              }}
              loading={categoryList.loading}
              allowClear
              isListScreen
            >
              {categoryList.data?.map((e) => (
                <Option key={e.id} value={e.id}>
                  {e.name}
                </Option>
              ))}
            </HookFormSelectAntd>

            {/* Status */}
            <HookFormSelectAntd
              name='status'
              placeholder='Status'
              control={control}
              size='large'
              classNameHeight='sapp-h-40'
            >
              {OPTIONS_PRODUCT_STATUS.map((status) => (
                <Option key={status.label} value={status.value}>
                  {status.label}
                </Option>
              ))}
            </HookFormSelectAntd>

            {/* Sort by */}
            <HookFormSelectAntd
              name='sortType'
              placeholder='Sort by'
              control={control}
              size='large'
              classNameHeight='sapp-h-40'
            >
              {FILTER_SELECTALL_SORTBY.map((status) => (
                <Option key={status.label} value={status.value}>
                  {status.label}
                </Option>
              ))}
            </HookFormSelectAntd>
          </ListFilterLayout>
        </div>
        <div className='card-header border-0 pt-5 px-10'>
          <div className='d-flex'>
            <SAPPFIlterButton
              titleReset='Reset'
              titleSubmit='Search'
              okClick={handleSubmit(handleFilter)}
              resetClick={handleResetFilter}
              disabled={isLoading}
              loading={isLoading}
            />
          </div>

          <div className='col-sm-8 col-xl-8 col-lg-4 px-xl-3 px-md-0 px-0 pe-xl-0'>
            <div className='card-toolbar justify-content-end d-flex my-0 gap-2'>
              <ButtonIcon
                title='New Product'
                isListScreen
                onClick={() => setIsOpenCreateModal(true)}
              >
                <KTIcon iconName='plus' className='fs-2' />
              </ButtonIcon>
            </div>
          </div>
        </div>
        {/* end:: filter */}

        {/* start:: table */}
        <SaleProductTable
          saleProducts={saleProducts}
          loading={isLoading}
          fetchData={fetchSaleProducts}
          handleChangeParams={handleChangeParams}
          getParams={getParams}
          setDataUpdate={setDataUpdate}
          setIsOpenUpdateModal={setIsOpenUpdateModal}
          setDeleteId={setDeleteId}
          setIsOpenDeleteModal={setIsOpenDeleteModal}
        />
        {/* end:: table */}

        <SaleProductSideModal
          type='create'
          isOpen={isOpenCreateModal}
          setIsOpen={setIsOpenCreateModal}
          fetchData={fetchSaleProducts}
        />

        <SaleProductSideModal
          type='update'
          isOpen={isOpenUpdateModal}
          setIsOpen={setIsOpenUpdateModal}
          fetchData={fetchSaleProducts}
          dataUpdate={dataUpdate}
        />

        <PopupConfirm
          open={isOpenDeleteModal}
          setOpen={setIsOpenDeleteModal}
          onClick={handleDelete}
          onClose={() => setIsOpenDeleteModal(false)}
          okButtonCaption='Yes'
          cancelButtonCaption='No'
          body={DELETE_POPUPCONFIRM}
        />
      </div>
    </PageLayouts>
  )
}

export default withAuthComponents(SaleProducts)
