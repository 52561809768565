import { zodResolver } from '@hookform/resolvers/zod'
import { format } from 'date-fns'
import { Dayjs } from 'dayjs'
import { ChangeEvent, useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useNavigate, useParams } from 'react-router-dom'
import { SaleProductAPI } from 'src/apis/sales/products'
import { PromotionCodeAPI } from 'src/apis/sales/promotion-codes'
import withAuthComponents from 'src/components/auth/with-auth-components'
import HookFormDateTime from 'src/components/base/datetime/HookFormDateTime'
import SappLabel from 'src/components/base/label/SappLabel'
import HookFormRadioGroup from 'src/components/base/radiobutton/HookFormRadioGroup'
import CustomTagsWithPlus from 'src/components/base/tag/CustomTagsWithPlus'
import HookFormTag from 'src/components/base/tag/HookFormTag'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import CreateEditLayout from 'src/components/layout/fullscreen/CreateEditLayout'
import CombinedPromotionCodeModal from 'src/components/sales/promotion-codes/CombinedPromotionCodeModal'
import { PageLink, VALIDATION_FIELD } from 'src/constants'
import { LANG_PROMOTION_CODE } from 'src/constants/lang'
import { DISCOUNT_TYPE, OPTIONS_DISCOUNT_TYPE, PRODUCT_STATUS } from 'src/constants/sales'
import { useConfirm } from 'src/hooks/use-confirm'
import { ICreatePromotionCode, IPromotionCode } from 'src/type/sales/promotionCodes'
import { z } from 'zod'

const CreateEditPromotionCode = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isOpenCombinedModal, setIsOpenCombinedModal] = useState<boolean>(false)
  const [discountType, setDiscountType] = useState<string>(DISCOUNT_TYPE.PERCENTAGE)
  const [combinedPromotionCodes, setCombinedPromotionCodes] = useState<Array<any>>([])
  const [editPromotionCode, setEditPromotionCode] = useState<IPromotionCode | null>(null)
  const { confirm, contextHolder } = useConfirm()
  const navigate = useNavigate()
  const { id } = useParams()

  const validation = z.object({
    code: z
      .string({
        message: VALIDATION_FIELD,
      })
      .trim()
      .min(1, {
        message: VALIDATION_FIELD,
      })
      .max(30, {
        message: 'Code must be less than 30 characters',
      }),
    quantity: z
      .string({
        message: VALIDATION_FIELD,
      })
      .min(1, {
        message: VALIDATION_FIELD,
      })
      .refine((val) => Number(val) >= 0, {
        message: 'Number must be greater than or equal to 0',
      }),
    start_date: z.any().refine((val) => val !== null && val !== undefined, {
      message: VALIDATION_FIELD,
    }),
    end_date: z.any().refine((val) => val !== null && val !== undefined, {
      message: VALIDATION_FIELD,
    }),
    apply_for: z.array(z.any(), {
      message: VALIDATION_FIELD,
    }),
    discount_type: z.string({
      message: VALIDATION_FIELD,
    }),
    discount_value: z
      .string({
        message: VALIDATION_FIELD,
      })
      .min(1, {
        message: VALIDATION_FIELD,
      })
      .refine((val) => Number(val) >= 0, {
        message: 'Number must be greater than or equal to 0',
      }),
    combined_with: z
      .array(z.any(), {
        message: VALIDATION_FIELD,
      })
      .min(1, {
        message: VALIDATION_FIELD,
      }),
  })

  const { control, handleSubmit, setValue, getValues } = useForm<ICreatePromotionCode>({
    resolver: zodResolver(validation),
    mode: 'onSubmit',
  })

  useEffect(() => {
    const fetchPromotionCode = async (id: string) => {
      setIsLoading(true)
      try {
        const res = await PromotionCodeAPI.getPromotionCode({ id })
        if (res?.success) {
          const data = res.data
          setEditPromotionCode(data)
          setValue('code', data.code)
          setValue('quantity', String(data.quantity))
          setValue('start_date', data.start_date)
          setValue('end_date', data.end_date)
          setValue('apply_for', data.products)
          setValue('discount_type', data.discount_type)
          setValue('discount_value', String(data.discount_value))
          setDiscountType(data.discount_type)
          setCombinedPromotionCodes(data.combinableWith)
        }
      } catch (err) {
        // Handed by axios interceptors
      } finally {
        setIsLoading(false)
      }
    }

    if (id) {
      fetchPromotionCode(id)
    }
  }, [])

  useEffect(() => {
    setValue(
      'combined_with',
      combinedPromotionCodes.map((item) => item.id)
    )
  }, [combinedPromotionCodes])

  const handleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn hủy không?'],
      onClick: () => {
        if (id) {
          navigate(`${PageLink.OPERATIONS_SALES_PROMOTION_CODES}/${id}`)
        } else {
          navigate(PageLink.OPERATIONS_SALES_PROMOTION_CODES)
        }
      },
    })
  }

  const handleSave = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn lưu không?'],
      onClick: handleSubmit(onSubmit),
    })
  }

  const onSubmit = async () => {
    const startDate = new Date(getValues('start_date') || new Date())
    const endDate = new Date(getValues('end_date') || new Date())
    const product_ids = getValues('apply_for')?.map((item) => item.id)

    const data = {
      code: getValues('code'),
      quantity: Number(getValues('quantity')),
      start_date: format(startDate, 'yyyy-MM-dd'),
      end_date: format(endDate, 'yyyy-MM-dd'),
      discount_type: getValues('discount_type'),
      discount_value: Number(getValues('discount_value')),
      promotion_code_ids: getValues('combined_with'),
      product_ids,
      status: 'NOT_STARTED',
    }

    setIsLoading(true)
    try {
      let res
      let message = ''
      let navigateUrl = ''
      if (id) {
        res = await PromotionCodeAPI.updatePromotionCode(id, data)
        message = 'Update promotion code successfully'
        navigateUrl = `${PageLink.OPERATIONS_SALES_PROMOTION_CODES}/${id}`
      } else {
        res = await PromotionCodeAPI.createPromotionCode(data)
        message = 'Create promotion code successfully'
        navigateUrl = PageLink.OPERATIONS_SALES_PROMOTION_CODES
      }

      if (res?.success) {
        toast.success(message)
        navigate(navigateUrl)
      }
    } catch (error) {
      // Handed by axios interceptors
    } finally {
      setIsLoading(false)
    }
  }

  const onDeselect = (value: string) => {
    setCombinedPromotionCodes(combinedPromotionCodes.filter((item) => item.id !== value))
  }

  const handleChangeDiscountType = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === DISCOUNT_TYPE.PERCENTAGE) {
      setDiscountType(DISCOUNT_TYPE.PERCENTAGE)
    } else {
      setDiscountType(DISCOUNT_TYPE.FIXED)
    }

    if (e.target.value === editPromotionCode?.discount_type) {
      setValue('discount_value', String(editPromotionCode?.discount_value))
    } else {
      setValue('discount_value', '')
    }
  }

  const handleDisableBeforeStartDate = (date: Dayjs) => {
    const startDate = getValues('start_date')
    return startDate && date.isBefore(startDate, 'day')
  }

  const handleDisableAfterEndDate = (date: Dayjs) => {
    const endDate = getValues('end_date')
    return endDate && date.isAfter(endDate, 'day')
  }

  return (
    <CreateEditLayout
      onSave={handleSave}
      onCancel={handleCancel}
      loading={isLoading}
      pageTitle={
        id ? LANG_PROMOTION_CODE.editPromotionCode : LANG_PROMOTION_CODE.createPromotionCode
      }
    >
      {contextHolder}
      <div className='card-body border-0 d-block px-10 py-0'>
        <Row className='mb-8'>
          <Col>
            <HookFormTextField
              control={control}
              name='code'
              label={LANG_PROMOTION_CODE.code}
              placeholder=''
              required
            />
          </Col>
        </Row>
        <Row className='mb-8'>
          <Col>
            <HookFormTextField
              control={control}
              type='number'
              minNumber={0}
              name='quantity'
              label={LANG_PROMOTION_CODE.quantity}
              placeholder=''
              required
            />
          </Col>
        </Row>
        <Row className='mb-8'>
          <Col md={6}>
            <HookFormDateTime
              control={control}
              name='start_date'
              label={LANG_PROMOTION_CODE.start}
              placeholder='Choose date'
              disabledDate={handleDisableAfterEndDate}
              required
            />
          </Col>
          <Col md={6}>
            <HookFormDateTime
              control={control}
              name='end_date'
              label={LANG_PROMOTION_CODE.end}
              placeholder='Choose date'
              disabledDate={handleDisableBeforeStartDate}
              required
            />
          </Col>
        </Row>
        <Row className='mb-8'>
          <Col>
            <HookFormTag
              control={control}
              name='apply_for'
              label={LANG_PROMOTION_CODE.applyForProduct}
              placeholder=''
              fetch={async ({
                text,
                page_index,
                page_size,
              }: {
                text: string
                page_index: number
                page_size: number
              }) => {
                return SaleProductAPI.getSaleProducts({
                  page_index,
                  page_size,
                  params: {
                    text,
                    status: PRODUCT_STATUS.ACTIVE,
                  },
                })
              }}
              classCustomTag='sapp-h-auto sapp-min-w--tag'
              className='sapp-h-auto sapp-tag-form-custom'
              dataName='products'
              isCanAddNew={false}
              required
            />
          </Col>
        </Row>
        <Row className='mb-8'>
          <Col md={6}>
            <SappLabel label={LANG_PROMOTION_CODE.discount} required />
            <HookFormRadioGroup
              control={control}
              name='discount_type'
              direction='horizontal'
              separator={false}
              justify='start'
              gap={32}
              labelClass='fw-semibold fs-6 sapp-table-class-field'
              onChange={handleChangeDiscountType}
              defaultValue={DISCOUNT_TYPE.PERCENTAGE}
              options={OPTIONS_DISCOUNT_TYPE}
            />
          </Col>
          <Col md={6}>
            <HookFormTextField
              control={control}
              type='number'
              name='discount_value'
              minNumber={0}
              maxNumber={discountType === DISCOUNT_TYPE.PERCENTAGE ? 100 : undefined}
              label=' '
              placeholder=''
              postFix={
                <div className='ps-3 pe-3 text-gray-700'>
                  {discountType === DISCOUNT_TYPE.PERCENTAGE ? '%' : 'đ'}
                </div>
              }
            />
          </Col>
        </Row>
        <Row className='mb-8'>
          <Col>
            <CustomTagsWithPlus
              control={control}
              name='combined_with'
              label='Combined with'
              onClick={() => setIsOpenCombinedModal(true)}
              selectOptions={combinedPromotionCodes.map((item) => ({
                value: item.id,
                name: item.code,
              }))}
              onDeselect={onDeselect}
              required
            />
          </Col>
        </Row>
      </div>

      <CombinedPromotionCodeModal
        isOpen={isOpenCombinedModal}
        setIsOpen={setIsOpenCombinedModal}
        combinedPromotionCodes={combinedPromotionCodes}
        setCombinedPromotionCodes={setCombinedPromotionCodes}
      />
    </CreateEditLayout>
  )
}

export default withAuthComponents(CreateEditPromotionCode)
