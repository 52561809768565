import { Dispatch, SetStateAction, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { Controller } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { OrderAPI } from 'src/apis/sales/orders'
import {
  DashIcon,
  DollarIcon,
  FilesFoldersIcon,
  PurchaseIcon,
  VNPayQRIcon,
  WalletIcon,
} from 'src/common/icons'
import SappLabel from 'src/components/base/label/SappLabel'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import Processbar from 'src/components/courses/course-detail/progress-bar'
import { PageLink } from 'src/constants'
import { useConfirm } from 'src/hooks/use-confirm'
import { IStep } from 'src/pages/shop/faqs/FaqDetail'
import { formatPrice, parsePrice } from 'src/utils'

interface IProp {
  setStep: Dispatch<SetStateAction<IStep[]>>
  step: IStep[]
  control: any
  getValues: any
  handleSubmit: any
  id?: string
}

const ConfirmTab = ({ setStep, step, control, getValues, handleSubmit, id }: IProp) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isShowCopy, setIsShowCopy] = useState<boolean>(false)
  const [copyLink, setCopyLink] = useState<string>('')
  const { confirm, contextHolder } = useConfirm()
  const navigate = useNavigate()

  const handleCancel = () => {
    confirm({
      okButtonCaption: 'Yes',
      cancelButtonCaption: 'No',
      body: ['Bạn có chắc chắn muốn hủy không?'],
      onClick: () => navigate(PageLink.OPERATIONS_SALES_ORDERS),
    })
  }

  const handleSave = () => {
    if (isShowCopy) {
      navigator.clipboard
        .writeText(copyLink) // Copy to clipboard
        .then(() => {
          toast.success('Copied to clipboard!')
        })
        .catch((err) => {
          toast.error('Failed to copy!')
        })
    } else {
      confirm({
        okButtonCaption: 'Yes',
        cancelButtonCaption: 'No',
        body: ['Bạn có chắc chắn muốn lưu không?'],
        onClick: handleSubmit(onSubmit),
      })
    }
  }

  const onSubmit = async () => {
    const data = {
      name: getValues('name'),
      email: getValues('email'),
      phoneNumber: getValues('phoneNumber'),
      fullName: getValues('fullName'),
      address: getValues('address'),
      province: getValues('province'),
      deposit: parsePrice(getValues('deposit')),
      product_ids: getValues('product_ids'),
      deal_id: getValues('deal_id'),
      promotion_code_ids: getValues('promotion_code_ids'),
    }

    setIsLoading(true)
    try {
      let res
      let message = ''
      if (!id) {
        res = await OrderAPI.createOrder(data)
        message = 'Create order successfully'
      } else {
        res = await OrderAPI.updateOrder(id, data)
        message = 'Update order successfully'
      }

      if (res?.success) {
        toast.success(message)
        setIsShowCopy(true)
        setCopyLink(res.data)
      }
    } catch (error) {
      // Handed by axios interceptors
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className='form d-flex flex-column flex-lg-row gap-7 mt-20'>
      {contextHolder}
      <div className='d-flex justify-content-center'>
        <Processbar
          step={step}
          setNewStep={setStep}
          cancelButtonCaption='Cancel'
          showCancel
          onCancel={handleCancel}
          onClick={handleSave}
          okButtonCaption={isShowCopy ? 'Copy Link' : 'Save'}
          className='mw-500px'
          loading={isLoading}
          // canToggle={handleToggle}
        />
      </div>

      <div className='d-flex flex-column gap-7 flex-grow-1'>
        <div className='card card-flush'>
          <div className='card-body sapp-px-40 sapp-py-20'>
            <div className='d-flex flex-column flex-lg-row justify-content-between'>
              <div className='d-flex flex-column sapp-w-430'>
                <div className='d-flex align-items-center gap-3'>
                  <div className='sapp-icon sapp-icon-yellow'>
                    <FilesFoldersIcon />
                  </div>
                  <SappLabel label='Thông tin thanh toán' className='mb-0' isLabel />
                </div>
                <div className='sapp-description'>
                  Vui lòng kiểm tra kỹ thông tin trước khi tiến hành thanh toán.
                </div>

                <Row>
                  <Col className='sapp-mb-24px'>
                    <HookFormTextField
                      control={control}
                      name='fullName'
                      label='Họ và tên'
                      placeholder=''
                      disabled
                    />
                  </Col>
                </Row>

                <Row>
                  <Col md={6} className='sapp-mb-24px'>
                    <HookFormTextField
                      control={control}
                      name='phoneNumber'
                      label='Số điện thoại'
                      placeholder=''
                      disabled
                    />
                  </Col>

                  <Col md={6} className='sapp-mb-24px'>
                    <HookFormTextField
                      control={control}
                      name='email'
                      label='Email'
                      placeholder=''
                      disabled
                    />
                  </Col>
                </Row>

                <Row>
                  <Col className='sapp-mb-24px'>
                    <HookFormTextField
                      control={control}
                      name='province'
                      label='Tỉnh/ Thành phố'
                      placeholder=''
                      disabled
                    />
                  </Col>
                </Row>

                <Row>
                  <Col className='sapp-mb-24px'>
                    <HookFormTextField
                      control={control}
                      name='address'
                      label='Địa chỉ'
                      placeholder=''
                      disabled
                    />
                  </Col>
                </Row>

                <Row>
                  <Col className='sapp-mb-24px'>
                    <HookFormTextField
                      control={control}
                      name='deal_id'
                      label='Deal ID'
                      placeholder=''
                      disabled
                    />
                  </Col>
                </Row>

                <div className='sapp-hotline'>
                  Nếu cần hỗ trợ, vui lòng liên hệ hotline: 1900 2225
                </div>
              </div>

              <div className='d-flex flex-column sapp-w-340'>
                <div className='d-flex align-items-center sapp-gap-3 sapp-mb-6'>
                  <div className='sapp-icon sapp-icon-yellow'>
                    <PurchaseIcon />
                  </div>
                  <SappLabel label='Thông tin đơn hàng' className='mb-0' isLabel />
                </div>

                <div className='d-flex flex-column mb-10'>
                  <div className='sapp-ticket-top'>
                    <div className='sapp-ticket-title'>
                      <Controller
                        control={control}
                        name='name'
                        render={({ field }) => <div>{field.value}</div>}
                      />
                    </div>
                    <div className='sapp-ticket-content'>
                      <div className='d-flex justify-content-between'>
                        <div>Giá</div>
                        <div>
                          <Controller
                            control={control}
                            name='price'
                            render={({ field }) => (
                              <div>{formatPrice(parsePrice(field.value), '.')} VNĐ</div>
                            )}
                          />
                        </div>
                      </div>
                      <div className='d-flex justify-content-between'>
                        <div>Đã thanh toán</div>
                        <Controller
                          control={control}
                          name='deposit'
                          render={({ field }) => (
                            <div>{formatPrice(parsePrice(field.value), '.')} VNĐ</div>
                          )}
                        />
                      </div>
                      <div className='d-flex justify-content-between'>
                        <div>Khuyến mãi</div>
                        <Controller
                          control={control}
                          name='promotionCodeValue'
                          render={({ field }) => {
                            return <div>0 VNĐ</div>
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='sapp-ticket-middle'>
                    <div className='sapp-ticket-semicircle'></div>
                    <div className='sapp-ticket-dash'>
                      <DashIcon />
                    </div>
                    <div className='sapp-ticket-semicircle'></div>
                  </div>
                  <div className='sapp-ticket-bottom'>
                    <div className='sapp-ticket-total'>
                      <div className='d-flex flex-column'>
                        <div>Tổng</div>
                        <Controller
                          control={control}
                          name='totalPrice'
                          render={({ field }) => {
                            return <div>{field.value ?? 0}</div>
                          }}
                        />
                      </div>
                      <div className='sapp-icon'>
                        <DollarIcon />
                      </div>
                    </div>
                  </div>
                </div>

                <div className='d-flex flex-column'>
                  <div className='d-flex align-items-center sapp-gap-3 sapp-mb-6'>
                    <div className='sapp-icon sapp-icon-yellow'>
                      <WalletIcon />
                    </div>
                    <SappLabel label='Phương thức thanh toán' className='mb-0' isLabel />
                  </div>
                  <div className='form-control sapp-h-45px sapp-ticket-payment'>
                    <div>Cổng thanh toán</div>
                    <VNPayQRIcon />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConfirmTab
