/* eslint-disable @typescript-eslint/no-unused-vars */
import { zodResolver } from '@hookform/resolvers/zod'
import { Select } from 'antd'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useLocation, useNavigate } from 'react-router-dom'
import { KTIcon } from 'src/_metronic/helpers'
import { OrderAPI } from 'src/apis/sales/orders'
import { SaleProductAPI } from 'src/apis/sales/products'
import PopupConfirm from 'src/common/PopupConfirm'
import SAPPFIlterButton from 'src/common/SAPPFIlterButton'
import withAuthComponents from 'src/components/auth/with-auth-components'
import ButtonIcon from 'src/components/base/button/ButtonIcon'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import ListFilterLayout from 'src/components/layout/listFilter'
import PageLayouts from 'src/components/layout/PageLayouts'
import OrderTable from 'src/components/sales/orders/OrderTable'
import { FILTER_SELECTALL_SORTBY, PageLink } from 'src/constants'
import { DELETE_POPUPCONFIRM, LANG_SIDEBAR } from 'src/constants/lang'
import { OPTIONS_ORDER_STATUS } from 'src/constants/sales'
import useShopFilter from 'src/hooks/use-shop-filter'
import { ITabs } from 'src/type'
import { IOrderFilter, IOrders } from 'src/type/sales/orders'
import { cleanParamsAPI } from 'src/utils'
import { z } from 'zod'
import './index.scss'

const { Option } = Select

const breadcrumbs: ITabs[] = [
  {
    link: `#`,
    title: `${LANG_SIDEBAR.operations}`,
  },
  {
    link: `#`,
    title: `${LANG_SIDEBAR.sales}`,
  },
  {
    link: `${PageLink.OPERATIONS_SALES_ORDERS}`,
    title: `${LANG_SIDEBAR.listOfOrders}`,
  },
]

const Orders = () => {
  const [data, setData] = useState<IOrders>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState<boolean>(false)
  const [deleteId, setDeleteId] = useState<string | null>(null)
  const { search } = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])
  const navigate = useNavigate()

  useEffect(() => {
    navigate('')
    fetchOrders(1, 10)
  }, [])

  /**
   * Validate cho các trường filter
   */
  const validationSchema = z.object({
    title: z.string().optional(),
    category: z.any().optional(),
    status: z.any().optional(),
    sortType: z.string().optional(),
    rangeDate: z.any().optional(),
  })

  const { control, handleSubmit, getValues, reset } = useForm<IOrderFilter>({
    resolver: zodResolver(validationSchema),
    mode: 'onSubmit',
  })

  const { dataList, getData, debounceGetData, handleNextPage } = useShopFilter({
    callback: SaleProductAPI.getSaleProducts,
    type: 'sale-product',
    key: 'products',
  })

  const fetchOrders = useCallback(
    async (pageIndex: number, pageSize: number, params?: Object) => {
      setIsLoading(true)
      try {
        const res = await OrderAPI.getOrders({
          page_index: pageIndex,
          page_size: pageSize,
          params,
        })
        setData(res?.data)
      } catch (error) {
        // Handed by axios interceptors
      } finally {
        setIsLoading(false)
      }
    },
    [setIsLoading, setData]
  )

  const getValuesFilter = () => ({
    product_id: getValues('product_id'),
    status: getValues('status'),
    sortType: getValues('sortType'),
  })

  const getParams = () => ({
    // code: searchParams.get('code'),
    product_id: searchParams.get('product_id'),
    status: searchParams.get('status'),
    sortType: searchParams.get('sortType'),
  })

  const handleChangeParams = (pageIndex: number, pageSize: number, params?: Object) => {
    const queryParams = {
      page_index: pageIndex,
      page_size: pageSize,
      ...params,
    }

    const queryString = Object.entries(queryParams)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    navigate(`?${encodeURI(queryString)}`)
  }

  const handleFilter = () => {
    const cleanedParams = cleanParamsAPI(getValuesFilter())

    handleChangeParams(1, 10, cleanedParams)
    fetchOrders(1, 10, cleanedParams)
  }

  const handleResetFilter = () => {
    reset()
    navigate('')
    fetchOrders(1, 10)
    getData()
  }

  const handleDelete = async () => {
    try {
      if (deleteId) {
        const res = await OrderAPI.deleteOrder(deleteId)
        if (res?.success) {
          toast.success('Delete order successfully')
          setDeleteId(null)
          fetchOrders(1, 10)
        }
      }
    } catch (error) {
      // Handed by axios interceptors
    } finally {
      setIsOpenDeleteModal(false)
    }
  }

  return (
    <PageLayouts pageTitle={LANG_SIDEBAR.listOfOrders} breadcrumbs={breadcrumbs}>
      <div className='card'>
        {/* start:: filter */}
        <div className='px-10 border-0 pt-10'>
          <ListFilterLayout>
            <HookFormTextField
              control={control}
              name='code'
              placeholder='Search'
              defaultValue=''
              isListScreen
              onSubmit={handleFilter}
            />

            <HookFormSelectAntd
              name='product_id'
              placeholder='Product'
              control={control}
              size='large'
              showSearch
              handleNextPage={handleNextPage}
              classNameHeight='sapp-h-40'
              onSearch={async (e) => {
                await debounceGetData(e)
              }}
              onFocus={async () => {
                if (dataList.data?.length === 0) {
                  await getData()
                }
              }}
              onChange={async (e: any) => {
                if (e === undefined) {
                  await debounceGetData()
                }
              }}
              loading={dataList.loading}
              allowClear
              isListScreen
            >
              {dataList.data?.map((e) => (
                <Option key={e.id} value={e.id}>
                  {e.name}
                </Option>
              ))}
            </HookFormSelectAntd>

            <HookFormSelectAntd
              control={control}
              name='status'
              placeholder='Status'
              size='large'
              classNameHeight='sapp-h-40'
            >
              {OPTIONS_ORDER_STATUS.map((status) => (
                <Option key={status.label} value={status.value}>
                  {status.label}
                </Option>
              ))}
            </HookFormSelectAntd>

            <HookFormSelectAntd
              control={control}
              name='sortType'
              placeholder='Sort by'
              size='large'
              classNameHeight='sapp-h-40'
            >
              {FILTER_SELECTALL_SORTBY.map((status) => (
                <Option key={status.label} value={status.value}>
                  {status.label}
                </Option>
              ))}
            </HookFormSelectAntd>
          </ListFilterLayout>
        </div>
        <div className='card-header border-0 pt-5 px-10'>
          <div className='d-flex'>
            <SAPPFIlterButton
              titleReset='Reset'
              titleSubmit='Search'
              okClick={handleSubmit(handleFilter)}
              resetClick={handleResetFilter}
              disabled={isLoading}
              loading={isLoading}
            />
          </div>

          <div className='col-sm-8 col-xl-8 col-lg-4 px-xl-3 px-md-0 px-0 pe-xl-0'>
            <div className='card-toolbar justify-content-end d-flex my-0 gap-2'>
              <ButtonIcon
                title='New Order'
                isListScreen
                onClick={() => navigate(`${PageLink.OPERATIONS_SALES_ORDERS}/create`)}
              >
                <KTIcon iconName='plus' className='fs-2' />
              </ButtonIcon>
            </div>
          </div>
        </div>
        {/* end:: filter */}

        {/* start:: table */}
        <OrderTable
          data={data}
          isLoading={isLoading}
          fetchData={fetchOrders}
          handleChangeParams={handleChangeParams}
          getParams={getParams}
          setDeleteId={setDeleteId}
          setIsOpenDeleteModal={setIsOpenDeleteModal}
        />
        {/* end:: table */}

        <PopupConfirm
          open={isOpenDeleteModal}
          setOpen={setIsOpenDeleteModal}
          onClick={handleDelete}
          onClose={() => setIsOpenDeleteModal(false)}
          okButtonCaption='Yes'
          cancelButtonCaption='No'
          body={DELETE_POPUPCONFIRM}
        />
      </div>
    </PageLayouts>
  )
}

export default withAuthComponents(Orders)
