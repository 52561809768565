import { zodResolver } from '@hookform/resolvers/zod'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'
import { CommonAPI } from 'src/apis'
import { OrderAPI } from 'src/apis/sales/orders'
import withAuthComponents from 'src/components/auth/with-auth-components'
import PageLayouts from 'src/components/layout/PageLayouts'
import ConfirmTab from 'src/components/sales/orders/ConfirmTab'
import CreateOrderTab from 'src/components/sales/orders/CreateOrderTab'
import ProductModal from 'src/components/sales/orders/ProductModal'
import PromotionCodeModal from 'src/components/sales/orders/PromotionCodeModal'
import { VALIDATION_FIELD } from 'src/constants'
import { LANG_ORDERS } from 'src/constants/lang'
import { IStep } from 'src/pages/shop/faqs/FaqDetail'
import { ICreateOrder, IProvince } from 'src/type/sales/orders'
import { formatPrice, parsePrice } from 'src/utils'
import { z } from 'zod'
import './index.scss'

const CreateEditOrder = () => {
  const { id } = useParams()
  const [step, setStep] = useState<IStep[]>([
    {
      title: `${id ? LANG_ORDERS.editOrder : LANG_ORDERS.createOrder}`,
      status: 'current',
      link: '',
    },
    {
      title: 'Confirm',
      status: '',
      link: '',
    },
  ])
  const [products, setProducts] = useState<Array<any>>([])
  const [isProductModal, setIsProductModal] = useState<boolean>(false)
  const [promotionCodes, setPromotionCodes] = useState<Array<any>>([])
  const [isPromotionCodesModal, setIsPromotionCodesModal] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [provinces, setProvinces] = useState<IProvince[]>([])

  const validation = z.object({
    fullName: z
      .string({
        message: VALIDATION_FIELD,
      })
      .trim()
      .min(1),
    email: z
      .string({
        message: VALIDATION_FIELD,
      })
      .trim()
      .min(1),
    phoneNumber: z
      .string({
        message: VALIDATION_FIELD,
      })
      .trim()
      .min(1),
    province: z.any().refine((val) => val !== null && val !== undefined, {
      message: VALIDATION_FIELD,
    }),
    address: z
      .string({
        message: VALIDATION_FIELD,
      })
      .trim()
      .min(1),
    deal_id: z
      .string({
        message: VALIDATION_FIELD,
      })
      .trim()
      .min(1),
    name: z
      .string({
        message: VALIDATION_FIELD,
      })
      .trim()
      .min(1),
    product_ids: z
      .array(z.any(), {
        message: VALIDATION_FIELD,
      })
      .min(1, {
        message: VALIDATION_FIELD,
      }),
  })

  const { control, watch, handleSubmit, setValue, getValues, reset } = useForm<ICreateOrder>({
    resolver: zodResolver(validation),
    mode: 'onSubmit',
  })

  useEffect(() => {
    const fetchOrder = async (id: string) => {
      setIsLoading(true)
      try {
        const res = await OrderAPI.getOrder(id)
        if (res?.success) {
          const data = res.data
          setValue('fullName', data.full_name)
          setValue('email', data.email)
          setValue('phoneNumber', data.phone_number)
          setValue('province', data.province)
          setValue('address', data.address)
          setValue('deal_id', data.deal_id)
          setValue('name', data.name)
          setValue('deposit', formatPrice(data.deposit))
          setValue('totalPrice', formatPrice(data.final_price, '.'))
          setProducts(data.products)
          setPromotionCodes(data?.promotion_codes ?? [])
        }
      } catch (err) {
        // Handed by axios interceptors
      } finally {
        setIsLoading(false)
      }
    }

    const fetchProvinces = async () => {
      try {
        const res = await CommonAPI.getProvince(1, 100)
        if (res?.success) {
          const data = res.data?.provinces ?? []
          setProvinces(
            data
              .filter((item: IProvince) => item.type === 'tinh' || item.type === 'thanh-pho')
              .sort((a: IProvince, b: IProvince) => a.name.localeCompare(b.name))
          )
        }
      } catch (err) {
        toast.error('Something went wrong')
      }
    }

    if (id) {
      fetchOrder(id)
    }
    fetchProvinces()
  }, [])

  useEffect(() => {
    const totalPrice = products?.reduce((pre, curr) => pre + curr.price, 0) ?? 0

    setValue('price', formatPrice(totalPrice))
    setValue(
      'product_ids',
      products.map((item) => item.id)
    )

    calculateTotalPrice()
  }, [products])

  useEffect(() => {
    setValue(
      'promotion_code_ids',
      promotionCodes.map((item) => item.id)
    )
  }, [promotionCodes])

  const calculateTotalPrice = useCallback(() => {
    const originPrice = parsePrice(getValues('price'))
    const depositPrice = parsePrice(String(getValues('deposit')))
    const discountPrice = 0
    const totalPrice = Math.max(originPrice - depositPrice - discountPrice, 0)

    setValue('totalPrice', formatPrice(totalPrice, '.'))
  }, [getValues, setValue])

  return (
    <PageLayouts
      titleTab=''
      pageTitle=''
      breadcrumbs={[]}
      showSideBar={false}
      classNameLayout='pt-20'
      showFooter={false}
      backgroudColor='bg-gray-100'
      removePadding={true}
    >
      <div className='sapp-mw-1200px mx-auto px-2'>
        {step?.[0]?.status === 'current' && (
          <CreateOrderTab
            step={step}
            setStep={setStep}
            control={control}
            watch={watch}
            setValue={setValue}
            getValues={getValues}
            handleSubmit={handleSubmit}
            setIsProductModal={setIsProductModal}
            setIsPromotionCodesModal={setIsPromotionCodesModal}
            products={products}
            setProducts={setProducts}
            promotionCodes={promotionCodes}
            setPromotionCodes={setPromotionCodes}
            isLoading={isLoading}
            provinces={provinces}
            calculateTotalPrice={calculateTotalPrice}
          />
        )}
        {step?.[1]?.status === 'current' && (
          <ConfirmTab
            step={step}
            setStep={setStep}
            control={control}
            getValues={getValues}
            handleSubmit={handleSubmit}
            id={id}
          />
        )}
      </div>

      <ProductModal
        isOpen={isProductModal}
        setIsOpen={setIsProductModal}
        products={products}
        setProducts={setProducts}
      />

      <PromotionCodeModal
        isOpen={isPromotionCodesModal}
        setIsOpen={setIsPromotionCodesModal}
        promotionCodes={promotionCodes}
        setPromotionCodes={setPromotionCodes}
        products={products}
      />
    </PageLayouts>
  )
}

export default withAuthComponents(CreateEditOrder)
