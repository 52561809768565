import { Dispatch, SetStateAction } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import { OrderAPI } from 'src/apis/sales/orders'
import LoadingTable from 'src/common/LoadingTable'
import SappTooltip from 'src/common/SappTooltip'
import PagiantionSAPP from 'src/components/base/pagination/PagiantionSAPP'
import SappTable from 'src/components/base/SappTable'
import { PageLink } from 'src/constants'
import { ORDER_STATUS, TITLES_ORDER_STATUS } from 'src/constants/sales'
import { IOrder, IOrders } from 'src/type/sales/orders'
import { cleanParamsAPI, formatPrice } from 'src/utils'
import OrderAction from './OrderAction'

interface IProps {
  data?: IOrders
  isLoading: boolean
  fetchData: (pageIndex: number, pageSize: number, params?: Object) => void
  handleChangeParams: (currenPage: number, size: number, params?: Object) => void
  getParams: () => Object
  setDeleteId: Dispatch<SetStateAction<string | null>>
  setIsOpenDeleteModal: Dispatch<SetStateAction<boolean>>
}

const headers = [
  {
    label: '#',
    className: 'min-w-30px px-2 text-center',
  },
  {
    label: 'Product',
    className: 'min-w-100px pe-5 ',
  },
  {
    label: 'Customer',
    className: 'min-w-100px pe-5 ',
  },
  {
    label: 'Deal ID',
    className: 'min-w-100px pe-5',
  },
  {
    label: 'Original Price',
    className: 'min-w-100px pe-5',
  },
  {
    label: 'Final Price',
    className: 'min-w-100px pe-5',
  },
  {
    label: 'Status',
    className: 'w-150px pe-5',
  },
  {
    label: '',
    className: 'w-50px pe-5',
  },
]

const OrderTable = ({
  data,
  isLoading,
  fetchData,
  handleChangeParams,
  getParams,
  setDeleteId,
  setIsOpenDeleteModal,
}: IProps) => {
  const navigate = useNavigate()

  const handlePaginationChange = (pageIndex: number, pageSize: number) => {
    const cleanedParams = cleanParamsAPI(getParams())

    handleChangeParams(pageIndex, pageSize, cleanedParams)
    fetchData(pageIndex, pageSize, cleanedParams)
  }

  const handleCopyLink = async (id: string) => {
    try {
      const resGetPaymentUrl = await OrderAPI.getPaymentUrl(id)
      if (resGetPaymentUrl?.success) {
        navigator.clipboard
          .writeText(resGetPaymentUrl.data) // Copy to clipboard
          .then(() => {
            toast.success('Copied to clipboard!')
          })
          .catch((err) => {
            toast.error('Failed to copy!')
          })
      }
    } catch (err) {
      // Handed by axios interceptors
    }
  }

  return (
    <div className='card-body pt-7'>
      <SappTable headers={headers} loading={isLoading} data={data?.orders} hasCheck={false}>
        {isLoading ? (
          <LoadingTable headers={headers} />
        ) : (
          <>
            {data?.orders.map((item: IOrder, index: number) => {
              let productNames = ''
              if (item.products.length > 1) {
                productNames = `Chuỗi khóa học "${item.products
                  .map((item) => item.name)
                  .join('; ')}"`
              } else {
                productNames = `${item.products.map((item) => item.name)}`
              }

              let statusClassName = 'sapp-badge'
              switch (item.status) {
                case ORDER_STATUS.INPROGRESS:
                  statusClassName += ' sapp-badge-inprogress'
                  break
                case ORDER_STATUS.SUCCESS:
                  statusClassName += ' sapp-badge-success'
                  break
                case ORDER_STATUS.CANCEL:
                  statusClassName += ' sapp-badge-cancel'
                  break
                default:
                  statusClassName += ''
              }

              return (
                <tr key={index}>
                  <td className='px-2 text-center'>
                    {index + 1 + (data?.metadata?.page_index - 1) * data?.metadata?.page_size}
                  </td>
                  <td className='text-start'>
                    <SappTooltip
                      title={productNames}
                      link={`${PageLink.OPERATIONS_SALES_ORDERS}/${item.id}/edit`}
                    />
                  </td>
                  <td className='text-start'>{item.full_name}</td>
                  <td className='text-start'>{item.deal_id}</td>
                  <td className='text-start'>{formatPrice(item.original_price)}</td>
                  <td className='text-start'>{formatPrice(item.final_price)}</td>
                  <td className='text-start'>
                    <span className={statusClassName}>
                      {TITLES_ORDER_STATUS[item.status] ?? ''}
                    </span>
                  </td>
                  <td className='text-center'>
                    <OrderAction
                      key={index}
                      handleEdit={() => {
                        navigate(`${PageLink.OPERATIONS_SALES_ORDERS}/${item.id}/edit`)
                      }}
                      handleDelete={() => {
                        setDeleteId(item.id)
                        setIsOpenDeleteModal(true)
                      }}
                      handleCopyLink={() => handleCopyLink(item.id)}
                    />
                  </td>
                </tr>
              )
            })}
          </>
        )}
      </SappTable>
      <PagiantionSAPP
        currentPage={data?.metadata?.page_index || 1}
        pageSize={data?.metadata?.page_size || 10}
        totalItems={data?.metadata?.total_records || 0}
        handlePaginationChange={handlePaginationChange}
      />
    </div>
  )
}
export default OrderTable
