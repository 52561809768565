import { zodResolver } from '@hookform/resolvers/zod'
import { Select } from 'antd'
import { Dispatch, memo, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { CoursesAPI } from 'src/apis/courses'
import { SaleProductAPI } from 'src/apis/sales/products'
import SAPPDialogButtonsCancelSubmit from 'src/common/SAPPDialogButtonsCancelSubmit'
import SappFilterButton from 'src/common/SAPPFIlterButton'
import SappModal from 'src/components/base/SappModal'
import HookFormSelectAntd from 'src/components/base/select/HookFormSelectAntd'
import HookFormTextField from 'src/components/base/textfield/HookFormTextField'
import ListFilterLayout from 'src/components/layout/listFilter'
import { FILTER_SELECTALL_SORTBY } from 'src/constants'
import { LANG_ORDERS } from 'src/constants/lang'
import { OPTIONS_PRODUCT_STATUS, PRODUCT_STATUS } from 'src/constants/sales'
import useChecked from 'src/hooks/use-checked'
import useShopFilter from 'src/hooks/use-shop-filter'
import { ISaleProductFilter, ISaleProducts } from 'src/type/sales/products'
import { cleanParamsAPI } from 'src/utils'
import { z } from 'zod'
import ProductTable from './ProductTable'

const { Option } = Select

interface IProps {
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
  setProducts: Dispatch<SetStateAction<Array<any>>>
  products: Array<any>
}

const ProductModal = ({ isOpen, setIsOpen, setProducts, products }: IProps) => {
  const [data, setData] = useState<ISaleProducts>()
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { search } = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])
  const navigate = useNavigate()
  const {
    checkedList,
    listDataChecked,
    setDefaultChecked,
    toggleCheck,
    toggleCheckAll,
    isCheckedAll,
  } = useChecked<any>(data?.products)

  const validation = z.object({
    name: z.string().optional(),
    category_id: z.string().optional(),
    status: z.string().optional(),
    sortType: z.string().optional(),
  })

  const { control, handleSubmit, getValues, reset } = useForm<ISaleProductFilter>({
    resolver: zodResolver(validation),
    mode: 'onSubmit',
  })

  const {
    dataList: categoryList,
    getData: getCategory,
    debounceGetData: debounceGetCategory,
    handleNextPage,
  } = useShopFilter({
    callback: CoursesAPI.getCategory,
    type: 'category',
    key: 'course_categories',
  })

  useEffect(() => {
    navigate('')
    fetchSaleProducts(1, 10)
  }, [])

  useEffect(() => {
    setDefaultChecked(products)
  }, [products])

  const fetchSaleProducts = useCallback(
    async (pageIndex: number, pageSize: number, params: Object = {}) => {
      setIsLoading(true)
      try {
        const res = await SaleProductAPI.getSaleProducts({
          page_index: pageIndex,
          page_size: pageSize,
          params: {
            ...params,
            status: PRODUCT_STATUS.ACTIVE,
          },
        })
        setData(res?.data)
      } catch (error) {
        // Handed by axios interceptors
      } finally {
        setIsLoading(false)
      }
    },
    [setIsLoading, setData]
  )

  const handleCancel = () => {
    setIsOpen(false)
  }

  const handleSave = () => {
    setProducts(listDataChecked)
    setIsOpen(false)
  }

  const getValuesFilter = () => ({
    name: getValues('name'),
    category_id: getValues('category_id'),
    status: getValues('status'),
    sortType: getValues('sortType'),
  })

  const getParams = () => ({
    name: searchParams.get('name'),
    category_id: searchParams.get('category_id'),
    status: searchParams.get('status'),
    sortType: searchParams.get('sortType'),
  })

  const handleChangeParams = (pageIndex: number, pageSize: number, params?: Object) => {
    const queryParams = {
      page_index: pageIndex,
      page_size: pageSize,
      ...params,
    }

    const queryString = Object.entries(queryParams)
      .map(([key, value]) => `${key}=${value}`)
      .join('&')

    navigate(`?${encodeURI(queryString)}`)
  }

  const handleFilter = () => {
    const cleanedParams = cleanParamsAPI(getValuesFilter())

    handleChangeParams(1, 10, cleanedParams)
    fetchSaleProducts(1, 10, cleanedParams)
  }

  const handleResetFilter = () => {
    reset()
    navigate('')
    fetchSaleProducts(1, 10)
    getCategory()
  }

  return (
    <SappModal
      title=''
      hideHeader
      open={isOpen}
      handleClose={handleCancel}
      dialogClassName={'m-0  modal-dialog-scrollable modal-fullscreen modal-fullscreen-form'}
      classNameBody={'sapp-m-h-unset'}
      showFooter={false}
    >
      <div className='d-flex justify-content-between px-7 flex-wrap align-items-center'>
        <div className='sapp-title-modal-create-class fs-3'>{LANG_ORDERS.products}</div>
        <div className='d-flex justify-content-between align-items-center'>
          {listDataChecked.length > 0 && (
            <div className='fw-medium fs-6 text-primary me-8'>
              {listDataChecked.length} Selected
            </div>
          )}
          <SAPPDialogButtonsCancelSubmit
            type='button'
            cancelClick={handleCancel}
            cancelButtonCaption='Cancel'
            okButtonCaption='Add'
            okOnClick={handleSave}
            className='justify-content-between d-flex flex-row-reverse'
            classNameCancel='fw-semibold sapp-fs-14 me-0 btn-sapp-filter me-3 btn btn-primary'
            classNameSubmit='fw-semibold sapp-fs-14 me-5'
          />
        </div>
      </div>

      {/* start: filter */}
      <div className='card-header pt-5'>
        <ListFilterLayout>
          <HookFormTextField
            control={control}
            name='name'
            placeholder='Product name'
            defaultValue=''
            isListScreen
            onSubmit={handleFilter}
          />

          <HookFormSelectAntd
            name='category_id'
            placeholder='Category'
            control={control}
            size='large'
            showSearch
            handleNextPage={handleNextPage}
            classNameHeight='sapp-h-40'
            onSearch={async (e) => {
              await debounceGetCategory(e)
            }}
            onFocus={async () => {
              if (categoryList.data?.length === 0) {
                await getCategory()
              }
            }}
            onChange={async (e: any) => {
              if (e === undefined) {
                await debounceGetCategory()
              }
            }}
            loading={categoryList.loading}
            allowClear
            isListScreen
          >
            {categoryList.data?.map((e) => (
              <Option key={e.id} value={e.id}>
                {e.name}
              </Option>
            ))}
          </HookFormSelectAntd>

          <HookFormSelectAntd
            name='status'
            placeholder='Status'
            control={control}
            size='large'
            classNameHeight='sapp-h-40'
          >
            {OPTIONS_PRODUCT_STATUS.map((status) => (
              <Option key={status.label} value={status.value}>
                {status.label}
              </Option>
            ))}
          </HookFormSelectAntd>

          <HookFormSelectAntd
            name='sortType'
            placeholder='Sort by'
            control={control}
            size='large'
            classNameHeight='sapp-h-40'
          >
            {FILTER_SELECTALL_SORTBY.map((status) => (
              <Option key={status.label} value={status.value}>
                {status.label}
              </Option>
            ))}
          </HookFormSelectAntd>
        </ListFilterLayout>
      </div>
      <div className='card-header pt-5'>
        <div className='d-flex'>
          <SappFilterButton
            titleReset='Reset'
            titleSubmit='Search'
            okClick={handleSubmit(handleFilter)}
            resetClick={handleResetFilter}
            disabled={isLoading}
            loading={isLoading}
          />
        </div>
      </div>
      {/* end: filter */}

      <ProductTable
        data={data}
        isLoading={isLoading}
        fetchSaleProducts={fetchSaleProducts}
        getParams={getParams}
        handleChangeParams={handleChangeParams}
        checkedList={checkedList}
        toggleCheck={toggleCheck}
        toggleCheckAll={toggleCheckAll}
        isCheckedAll={isCheckedAll}
      />
    </SappModal>
  )
}

export default memo(ProductModal)
