import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { PromotionCodeAPI } from 'src/apis/sales/promotion-codes'
import withAuthComponents from 'src/components/auth/with-auth-components'
import PageLayouts from 'src/components/layout/PageLayouts'
import BodyDetail from 'src/components/sales/promotion-codes/BodyDetail'
import HeaderDetail from 'src/components/sales/promotion-codes/HeaderDetail'
import { PageLink } from 'src/constants'
import { LANG_SIDEBAR } from 'src/constants/lang'
import { PROMOTION_CODE_DETAIL_URL } from 'src/constants/sales'
import { ITabs } from 'src/type'
import { IPromotionCode } from 'src/type/sales/promotionCodes'

const breadcrumbs: ITabs[] = [
  {
    link: `#`,
    title: LANG_SIDEBAR.operations,
  },
  {
    link: `#`,
    title: LANG_SIDEBAR.sales,
  },
  {
    link: `${PageLink.OPERATIONS_SALES_PROMOTION_CODES}`,
    title: LANG_SIDEBAR.promotionCodes,
  },
  {
    link: `#`,
    title: LANG_SIDEBAR.detail,
  },
]

const DetailPromotionCode = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [promotionCode, setPromotionCode] = useState<IPromotionCode | null>(null)
  const { id } = useParams()

  // Config Tabs
  const tabs: ITabs[] = [
    {
      link: `${PageLink.OPERATIONS_SALES_PROMOTION_CODES}/${id}/${PROMOTION_CODE_DETAIL_URL.OVERVIEW}`,
      title: LANG_SIDEBAR.overview,
    },
    {
      link: `${PageLink.OPERATIONS_SALES_PROMOTION_CODES}/${id}/${PROMOTION_CODE_DETAIL_URL.LIST_ORDERS}`,
      title: LANG_SIDEBAR.listOrders,
    },
  ]

  useEffect(() => {
    const fetchPromotionCode = async (id: string) => {
      setIsLoading(true)
      try {
        const res = await PromotionCodeAPI.getPromotionCode({ id })
        if (res?.success) {
          setPromotionCode(res.data)
        }
      } catch (err) {
        // Handed by axios interceptors
      } finally {
        setIsLoading(false)
      }
    }

    if (id) {
      fetchPromotionCode(id)
    }
  }, [])

  return (
    <PageLayouts
      pageTitle='Promotion Code Detail'
      breadcrumbs={breadcrumbs}
      classNameLayout='sapp-overflow-y-layout'
    >
      <HeaderDetail promotionCode={promotionCode} tabs={tabs} loading={isLoading} />
      <BodyDetail promotionCode={promotionCode} loading={isLoading} />
    </PageLayouts>
  )
}

export default withAuthComponents(DetailPromotionCode)
